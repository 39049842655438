.root {
  // contained primary
  --contained-primary-bg-color: var(--color-acqua);
  --contained-primary-text-color: black;
  --contained-primary-icon-color: black;
  // contained error
  --contained-error-bg-color: var(--color-error);
  --contained-error-icon-color: black;
  --contained-error-text-color: black;
  // outlined error
  --outlined-error-bg-color: transparent;
  --outlined-error-border-color: black;
  --outlined-error-text-color: black;
}

html[data-theme="light"] .root {
  // text contrast
  --text-contrast-color: black;
  // contained contrast
  --contained-contrast-bg-color: var(--color-ink);
  --contained-contrast-bg-hover-color: var(--color-graphite);
  --contained-contrast-text-color: white;
  --contained-contrast-icon-color: white;
  // outlined secondary
  --outlined-secondary-bg-color: white;
  --outlined-secondary-border-color: var(--color-cloud);
  --outlined-secondary-text-color: black;
  // outlined contrast
  --outlined-contrast-bg-color: transparent;
  --outlined-contrast-border-color: var(--color-smoke);
  --outlined-contrast-text-color: black;
  // text primary
  --text-primary-color: var(--color-ocean);
  // text contrast
  --text-contrast-color: black;
}

html[data-theme="dark"] .root {
  // contained contrast
  --contained-contrast-bg-color: white;
  --contained-contrast-bg-hover-color: var(--color-cloud);
  --contained-contrast-text-color: black;
  --contained-contrast-icon-color: black;
  // outlined secondary
  --outlined-secondary-bg-color: var(--color-ink);
  --outlined-secondary-border-color: var(--color-smoke);
  --outlined-secondary-text-color: white;
  // outlined contrast
  --outlined-contrast-bg-color: transparent;
  --outlined-contrast-border-color: var(--color-smoke);
  --outlined-contrast-text-color: white;
  // text primary
  --text-primary-color: var(--color-acqua);
  // text contrast
  --text-contrast-color: white;
}

.root {
  border-radius: 8px;
  font-weight: bold;

  transition: filter 0.2s ease-in-out;

  &,
  &:hover {
    box-shadow: none;
  }

  label {
    position: relative;
    top: 1px;
    text-transform: none;
    cursor: pointer;
  }

  &:hover {
    transition: filter 0.2s ease-in-out;
  }

  &.outlined {
    border-width: 1.5px;
  }

  &.contained.primary {
    color: var(--contained-primary-text-color);
    background-color: var(--contained-primary-bg-color);

    &:hover {
      background-color: var(--contained-primary-bg-color);
    }

    svg {
      width: 24px;
      height: 24px;
    }
  }

  &.contained.error {
    color: var(--contained-error-text-color);
    background-color: var(--contained-error-bg-color);

    &:hover {
      background-color: var(--contained-error-bg-color);
    }

    svg {
      width: 24px;
      height: 24px;
      fill: var(--contained-error-icon-color);
    }
  }

  &.contained.contrast {
    background-color: var(--contained-contrast-bg-color);

    &:hover {
      background-color: var(--contained-contrast-bg-color);
    }

    label {
      color: var(--contained-contrast-text-color);
    }

    path {
      fill: var(--contained-contrast-icon-color);
    }
  }

  &.outlined.secondary {
    color: var(--outlined-secondary-text-color);
    border: 1px solid var(--outlined-secondary-border-color);
    background-color: var(--outlined-secondary-bg-color);

    &:hover {
      background-color: var(--outlined-secondary-bg-color);
    }
  }

  &.outlined.error {
    color: var(--outlined-error-text-color);
    border: 1px solid var(--outlined-secondary-border-color);
    background-color: var(--outlined-error-bg-color);

    &:hover {
      background-color: var(--outlined-error-bg-color);
    }
  }

  &.outlined.contrast {
    color: var(--outlined-contrast-text-color);
    border: 1px solid var(--outlined-contrast-border-color);
    background-color: var(--outlined-contrast-bg-color);
    font-weight: 500;

    &:hover {
      background-color: var(--outlined-contrast-bg-color);
    }
  }

  &.text.primary {
    color: var(--text-primary-color);

    path {
      stroke: var(--text-primary-color);
    }
  }

  &.text.contrast {
    color: var(--text-contrast-color);
  }
}

html[data-theme="light"] .root {
  &:hover {
    filter: brightness(95%);
  }

  &.contained:disabled {
    filter: opacity(0.4);
  }

  &.contrast {
    &:hover {
      filter: brightness(1.8);
    }

    &:disabled {
      filter: brightness(7.6);
    }
  }

  &.text:hover,
  &.text:disabled {
    filter: opacity(0.6);
  }
}

html[data-theme="dark"] .root {
  &:hover {
    filter: brightness(85%);
  }

  &.contained:disabled,
  &.contrast:disabled {
    filter: brightness(0.4);
  }

  &.text:hover,
  &.text:disabled {
    filter: opacity(0.4);
  }
}
