@font-face {
  font-family: "Articulat CF";
  src: url("/fonts/articulat-cf/ArticulatCF-Regular.woff2") format("woff2"),
    url("/fonts/articulat-cf/ArticulatCF-Regular.woff") format("woff");
  font-weight: 400; // normal
}

@font-face {
  font-family: "Articulat CF";
  src: url("/fonts/articulat-cf/ArticulatCF-Medium.woff2") format("woff2"),
    url("/fonts/articulat-cf/ArticulatCF-Medium.woff") format("woff");
  font-weight: 500;
}

@font-face {
  font-family: "Articulat CF";
  src: url("/fonts/articulat-cf/ArticulatCF-DemiBold.woff2") format("woff2"),
    url("/fonts/articulat-cf/ArticulatCF-DemiBold.woff") format("woff");
  font-weight: 600;
}

@font-face {
  font-family: "Articulat CF";
  src: url("/fonts/articulat-cf/ArticulatCF-Bold.woff2") format("woff2"),
    url("/fonts/articulat-cf/ArticulatCF-Bold.woff") format("woff");
  font-weight: 700; // bold
}

@font-face {
  font-family: "Geomanist";
  src: url("/fonts/geomanist/geomanist-regular-webfont.eot");
  src: url("/fonts/geomanist/geomanist-regular-webfont.eot?#iefix")
      format("embedded-opentype"),
    url("/fonts/geomanist/geomanist-regular-webfont.woff2") format("woff2"),
    url("/fonts/geomanist/geomanist-regular-webfont.woff") format("woff"),
    url("/fonts/geomanist/geomanist-regular-webfont.ttf") format("truetype"),
    url("/fonts/geomanist/geomanist-regular-webfont.svg#geomanistregular")
      format("svg");
  font-weight: 400;
}

@font-face {
  font-family: "Geomanist";
  src: url("/fonts/geomanist/geomanist-medium-webfont.eot");
  src: url("/fonts/geomanist/geomanist-medium-webfont.eot?#iefix")
      format("embedded-opentype"),
    url("/fonts/geomanist/geomanist-medium-webfont.woff2") format("woff2"),
    url("/fonts/geomanist/geomanist-medium-webfont.woff") format("woff"),
    url("/fonts/geomanist/geomanist-medium-webfont.ttf") format("truetype"),
    url("/fonts/geomanist/geomanist-medium-webfont.svg#geomanistregular")
      format("svg");
  font-weight: 500;
}

@font-face {
  font-family: "Geomanist";
  src: url("/fonts/geomanist/geomanist-medium-webfont.eot");
  src: url("/fonts/geomanist/geomanist-medium-webfont.eot?#iefix")
      format("embedded-opentype"),
    url("/fonts/geomanist/geomanist-medium-webfont.woff2") format("woff2"),
    url("/fonts/geomanist/geomanist-medium-webfont.woff") format("woff"),
    url("/fonts/geomanist/geomanist-medium-webfont.ttf") format("truetype"),
    url("/fonts/geomanist/geomanist-medium-webfont.svg#geomanistregular")
      format("svg");
  font-weight: 600;
}

$font-family: "Articulat CF", Inter, arial, sans-serif;

@mixin primary-font {
  font-family: $font-family;
  letter-spacing: 0.2px;
}
