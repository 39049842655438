.root {
  display: flex;
  flex-direction: row;
  gap: 2px;
  padding: 8px;

  .text {
    flex: 1;
    padding: 0 6px;

    h5 {
      margin: 3px 0 0;
      font-size: 14px;
      font-weight: 500;
      line-height: 14px;
    }

    p {
      margin: 4px 0 0;
      font-size: 14px;
      line-height: 18px;
    }

    .extraButton {
      margin-top: 10px;
      text-align: end;

      button {
        padding: 4px 10px;
        font-weight: normal;
      }
    }
  }

  .close {
    button {
      padding: 0;

      svg {
        color: var(--color-smoke);
      }
    }
  }

  // &.info {}

  &.error {
    background-color: #ffbaba;
    color: black;
  }
}
