@import "./typography.scss";

/* Transitions */

.trans-opacity-enter {
  opacity: 0;
}

.trans-opacity-enter-active,
.trans-opacity-enter-done {
  opacity: 1;
  transition: opacity 150ms;
}
.trans-opacity-exit {
  opacity: 1;
}

.trans-opacity-exit-active,
.trans-opacity-exit-done {
  opacity: 0;
  transition: opacity 50ms;
  transition-delay: 100ms;
}
