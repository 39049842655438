:root {
  --background-color: black;
  --text-color-smoke: var(--color-smoke);
  --text-color-error: var(--color-error);
  --text-secondary-color: var(--color-smoke);
  --border-color: var(--color-smoke);
  --disabled-text-color: #aaaaaa;
  --text-caption: var(--color-gray-30);
  --custom-input-text-color: var(--color-white);
  --custom-input-text-disabled: var(--color-graphite);
  --custom-input-text-placeholder: var(--color-white-50A);
  --custom-input-clear-icon: var(--color-white-50A);
  --custom-button-rounded: var(--color-gray-700);

  /* Colors */
  --color-ink: #1d1d1d;
  --color-oil: #262626;
  --color-charcoal: #333333;
  --color-graphite: #3b3b3b;
  --color-smoke: #666666;
  --color-cloud: var(--color-gray-30);
  --color-snow: #fafafa; /* temporary name */
  --color-gray-10: #e8e8e8;
  --color-gray-20: #d1d1d1;
  --color-gray-30: #bfbfbf;
  --color-gray-40: #a8a8a8;
  --color-gray-50: #949494;
  --color-gray-60: #858585;
  --color-gray-70: #737373;
  --color-gray-80: #666666;
  --color-gray-90: #545454;
  --color-gray-100: #474747;
  --color-gray-700: #1d1d1d;
  --color-acqua: #00dae8;
  --color-acqua-100: #98fcff;
  --color-acqua-a80: #00dae880;
  --color-ocean: #00b4cc;
  --color-ocean-a80: #00b4cc80;
  --color-clear-acqua: #e0fbfc;
  --color-error: #ff613d;
  --color-mint-500: #0affa7;
  /*  Primary + Opacity - black */
  --color-black: #000;
  --color-black-5A: rgb(0, 0, 0, 0.05);
  --color-black-10A: rgb(0, 0, 0, 0.1);
  --color-black-15A: rgb(0, 0, 0, 0.15);
  --color-black-20A: rgb(0, 0, 0, 0.2);
  --color-black-25A: rgb(0, 0, 0, 0.25);
  --color-black-30A: rgb(0, 0, 0, 0.3);
  --color-black-35A: rgb(0, 0, 0, 0.35);
  --color-black-40A: rgb(0, 0, 0, 0.4);
  --color-black-45A: rgb(0, 0, 0, 0.45);
  --color-black-50A: rgb(0, 0, 0, 0.5);
  --color-black-60A: rgb(0, 0, 0, 0.6);
  --color-black-70A: rgb(0, 0, 0, 0.7);
  --color-black-80A: rgb(0, 0, 0, 0.8);
  --color-black-90A: rgb(0, 0, 0, 0.9);
  /*  Primary + Opacity - white */
  --color-white: #fff;
  --color-white-5A: rgb(255, 255, 255, 0.05);
  --color-white-10A: rgb(255, 255, 255, 0.1);
  --color-white-15A: rgb(255, 255, 255, 0.15);
  --color-white-20A: rgb(255, 255, 255, 0.2);
  --color-white-25A: rgb(255, 255, 255, 0.25);
  --color-white-30A: rgb(255, 255, 255, 0.3);
  --color-white-35A: rgb(255, 255, 255, 0.35);
  --color-white-40A: rgb(255, 255, 255, 0.4);
  --color-white-45A: rgb(255, 255, 255, 0.45);
  --color-white-50A: rgb(255, 255, 255, 0.5);
  --color-white-60A: rgb(255, 255, 255, 0.6);
  --color-white-70A: rgb(255, 255, 255, 0.7);
  --color-white-80A: rgb(255, 255, 255, 0.8);
  --color-white-90A: rgb(255, 255, 255, 0.9);
}

html[data-theme="light"] {
  --background-color: var(--color-snow);
  --text-color: black;
  --text-secondary-color: var(--color-smoke);
  --text-caption: var(--color-gray-50);
  --custom-input-text-color: var(--color-gray-50);
  --custom-input-text-disabled: var(--color-gray-50);
  --custom-input-text-placeholder: var(--color-gray-50);
  --custom-input-clear-icon: var(--color-gray-50);
  --custom-button-rounded: var(--color-gray-10);
  --primary-color: var(--color-ocean);
  --pill-bg-color: #f2f2f2;
  --pill-bg-color-hover: #d9d9d9;
  --border-color: #f0f0f0;
  --modal-bg-color: white;
  --modal-close-button-color: black;
  --modal-section-text-color: black;
  --modal-description-text-color: var(--color-smoke);
  --syllables-count-text-color: rgba(0, 0, 0, 0.16);
  --switch-track-color: var(--color-cloud);
  /* Editor */
  --selection-text-color: black;
  --selection-background-color: var(--color-ocean-a80);
  /* Suggestions box */
  --suggestions-bg-color: white;
  --suggestions-title-color: black;
  --suggestions-button-color: black;
  --suggestions-input-border-color: var(--color-oil);
  --suggestions-active-button-color: black;
  --suggestions-active-button-bg-color: var(--color-clear-acqua);
  --suggestions-label-color: var(--color-cloud);
  --suggestions-footer-bg-color: #f5f5f5;
  --suggestions-footer-button-color: var(--color-ocean);
  --suggestions-footer-subtitle-color: var(--color-cloud);
  --suggestions-menu-bg-color: white;
  --suggestions-menu-text-color: var(--color-ink);
  --suggestions-menu-item-icon-color: var(--color-smoke);
  --suggestions-empty-text-color: var(--text-secondary-color);
  --suggestions-hide-button-bg-color: white;
  --suggestions-header-border-color: var(--color-gray-10);
  --suggestions-menu-icon-color: var(--color-cloud);
  --suggestions-no-load-more-text-color: var(--disabled-text-color);
  /* Input */
  --input-border-color: var(--color-cloud);
  --input-placeholder-color: var(--color-cloud);
  --input-icon-color: var(--color-gray-50);
  /* Back button */
  --back-button-bg-color: #e6e6e6;
  --back-button-icon-color: var(--color-smoke);
  --back-button-hover-bg-color: #dbdbdb;
  /* Library */
  --library-text-color: black;
  --library-item-bg-color: white;
  --library-item-hover-bg-color: #f8f8f8;
  --library-item-text-color: var(--color-ocean);
  --library-item-title-color: black;
  --library-item-content-color: var(--color-smoke);
  --library-item-date-color: var(--color-cloud);
  --library-card-new-icon-filter: brightness(80%);
  --library-card-icon-filter: grayscale(100%) brightness(85%);
  /* Toggle */
  --toggle-description: var(--color-gray-60);
  /* Copiable Link */
  --copiable-link-border-color: var(--color-smoke);
  --copiable-link-input-bg-color: white;
  --copiable-link-input-text-color: var(--color-ocean);
  --copiable-link-disabled-input-bg-color: var(--color-gray-10);
  /* Settings Dialog */
  --settings-dialog-bg-color: white;
  --settings-dialog-button-bg-color: var(--color-acqua);
  --settings-dialog-button-text-color: black;
  /* Mood Selector */
  --mood-selector-item-bg-color: var(--color-gray-10);
  --mood-selector-item-hover-bg-color: var(--color-gray-20);
  --mood-selector-item-hover-text-color: black;
  --mood-selector-item-active-bg-color: black;
  --mood-selector-item-active-text-color: white;
  /* Modals */
  --modal-bg-color: var(--color-snow);
  --modal-title-color: black;
  --modal-text-color: var(--color-smoke);
  /* Checkbox */
  --checkbox-description-color: var(--color-smoke);
  /* MUI AutoComplete */
  --autocomplete-label-color: var(--color-smoke);
  --autocomplete-bg-color: white;
  --autocomplete-hover-bg-color: var(--color-snow);
  --autocomplete-icon-color: var(--color-cloud);
  /* MUI Tooltip */
  --tooltip-bg-color: black;
  --tooltip-text-color: white;
  /* MUI Dialog */
  --dialog-bg-color: white;
  /* Language warning modal */
  --language-warning-modal-bg-color: var(--color-ink);
  --language-warning-modal-text-color: var(--color-cloud);
  /* Mood help dialog */
  --mood-help-dialog-content-color: var(--color-gray-100);
  --mood-help-dialog-mood-button-border-color: var(--color-gray-700);
  /* Sharing Dialog */
  --sharing-dialog-bg-color: white;
  /* Upgrade dialog */
  --upgrade-dialog-icon-color: var(--color-ocean);
  /* Metric Settings */
  --metric-settings-label-disabled-color: var(--color-gray-40);
  --metric-settings-label-enabled-color: black;
  --metric-settings-content-bg-color: var(--color-snow);
  --metric-settings-content-disabled-color: var(--color-gray-40);
  --metric-settings-content-enabled-color: black;
  --metric-settings-value-bg-color: var(--color-gray-10);
  --metric-settings-disabled-shadow-color: #0000000f;
  /* Sharing Label */
  --sharing-label-disabled-color: var(--color-gray-50);
}

html[data-theme="dark"] {
  --background-color: black;
  --text-color: white;
  --text-secondary-color: var(--color-smoke);
  --primary-color: var(--color-acqua);
  --pill-bg-color: #333333;
  --pill-bg-color-hover: #5a5a5a;
  --border-color: var(--color-smoke);
  --syllables-count-text-color: rgba(255, 255, 255, 0.3);
  --modal-bg-color: var(--color-oil);
  --modal-close-button-color: white;
  --modal-section-text-color: white;
  --modal-description-text-color: var(--color-cloud);
  --switch-track-color: var(--color-smoke);
  /* Editor */
  --selection-text-color: white;
  --selection-background-color: var(--color-acqua-a80);
  /* Suggestions box */
  --suggestions-bg-color: var(--color-oil);
  --suggestions-title-color: white;
  --suggestions-button-color: white;
  --suggestions-input-border-color: var(--color-cloud);
  --suggestions-active-button-color: var(--color-acqua);
  --suggestions-active-button-bg-color: var(--color-ink);
  --suggestions-label-color: var(--color-smoke);
  --suggestions-footer-bg-color: #2f2f2f;
  --suggestions-footer-button-color: var(--color-ocean);
  --suggestions-footer-subtitle-color: var(--color-smoke);
  --suggestions-menu-bg-color: var(--color-ink);
  --suggestions-menu-text-color: white;
  --suggestions-menu-item-icon-color: var(--color-cloud);
  --suggestions-empty-text-color: var(--color-cloud);
  --suggestions-hide-button-bg-color: var(--color-oil);
  --suggestions-header-border-color: var(--color-graphite);
  --suggestions-menu-icon-color: var(--color-cloud);
  --suggestions-no-load-more-text-color: var(--color-gray-30);
  /* Input */
  --input-border-color: var(--color-graphite);
  --input-placeholder-color: var(--color-smoke);
  --input-icon-color: var(--color-gray-30);
  /* Back button */
  --back-button-bg-color: var(--color-ink);
  --back-button-icon-color: white;
  --back-button-hover-bg-color: #292929;
  /* Library */
  --library-text-color: white;
  --library-item-bg-color: rgba(38, 38, 38, 1);
  --library-item-hover-bg-color: rgba(48, 48, 48, 1);
  --library-item-text-color: var(--color-acqua);
  --library-item-title-color: white;
  --library-item-content-color: var(--color-cloud);
  --library-item-date-color: var(--color-smoke);
  --library-card-icon-filter: grayscale(100%) brightness(70%);
  /* Toggle */
  --toggle-description: var(--color-cloud);
  /* Copiable Link */
  --copiable-link-border-color: transparent;
  --copiable-link-input-bg-color: var(--color-ink);
  --copiable-link-input-text-color: var(--color-acqua);
  --copiable-link-disabled-input-bg-color: var(--color-ink);
  /* Settings Dialog */
  --settings-dialog-bg-color: var(--color-ink);
  --settings-dialog-button-bg-color: var(--color-acqua);
  --settings-dialog-button-text-color: black;
  /* Mood Selector */
  --mood-selector-item-bg-color: var(--color-graphite);
  --mood-selector-item-hover-bg-color: black;
  --mood-selector-item-hover-text-color: white;
  --mood-selector-item-active-bg-color: white;
  --mood-selector-item-active-text-color: black;
  /* Modals */
  --modal-bg-color: var(--color-ink);
  --modal-title-color: white;
  --modal-text-color: var(--color-cloud);
  /* Checkbox */
  --checkbox-description-color: var(--color-smoke);
  /* MUI AutoComplete */
  --autocomplete-label-color: var(--color-cloud);
  --autocomplete-bg-color: var(--color-ink);
  --autocomplete-hover-bg-color: rgba(0, 0, 0, 0.15);
  --autocomplete-icon-color: var(--color-smoke);
  /* MUI Tooltip */
  --tooltip-bg-color: white;
  --tooltip-text-color: black;
  /* MUI Dialog */
  --dialog-bg-color: var(--color-ink);
  /* Language warning modal */
  --language-warning-modal-bg-color: var(--color-ink);
  --language-warning-modal-text-color: var(--color-cloud);
  /* Mood help dialog */
  --mood-help-dialog-content-color: var(--color-gray-40);
  --mood-help-dialog-mood-button-border-color: var(--color-gray-90);
  /* Sharing Dialog */
  --sharing-dialog-bg-color: var(--color-charcoal);
  /* Upgrade dialog */
  --upgrade-dialog-icon-color: var(--color-mint-500);
  /* Metric Settings */
  --metric-settings-label-disabled-color: var(--color-gray-80);
  --metric-settings-label-enabled-color: white;
  --metric-settings-content-bg-color: var(--color-graphite);
  --metric-settings-content-disabled-color: var(--color-gray-60);
  --metric-settings-content-enabled-color: white;
  --metric-settings-value-bg-color: var(--color-gray-100);
  --metric-settings-disabled-shadow-color: #0000001f;
  /* Sharing Label */
  --sharing-label-disabled-color: var(--color-gray-70);
}
