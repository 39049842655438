@import "./typography.scss";

.MuiButtonBase-root,
.MuiInputBase-input,
.MuiDialogTitle-root {
  @include primary-font;
}

.MuiOutlinedInput-root {
  color: var(--text-color) !important;
  font-weight: 400 !important;
  font-size: 16px !important;
}

.MuiOutlinedInput-notchedOutline {
  border: 2px solid var(--input-border-color) !important;
}

input.MuiFilledInput-input {
  -webkit-tap-highlight-color: transparent;
  animation: mui-auto-fill-cancel 10ms;
  background-color: var(--pill-bg-color) !important;
  color: var(--text-color) !important;
}

div.MuiFilledInput-root:after {
  border-bottom: 2px solid var(--primary-color);
  border-radius: 100px !important;
}

div.MuiFilledInput-root:before {
  border-bottom: 1px solid var(--border-color);
}

div.MuiFilledInput-root:hover {
  border-bottom: 1px solid var(--border-color) !important;
}

input.MuiInput-input {
  color: var(--text-color) !important;
}

*.MuiInputBase-root:after {
  border-bottom: 2px solid var(--primary-color) !important;
}

*.MuiInputBase-root:before {
  border-bottom: 2px solid var(--border-color) !important;
}

#modal-modal-title {
  font-weight: 600 !important;
}

label.MuiInputLabel-root,
label.MuiInputLabel-root.Mui-focused {
  @include primary-font;
  font-size: 16px;
  font-weight: 400;
  color: var(--text-color);
}

.MuiFormControl-root {
  display: flex !important;
}

/****************/
/* AutoComplete */

.MuiAutocomplete-root .MuiInputLabel-root {
  color: var(--autocomplete-label-color) !important;
}

.MuiAutocomplete-root .MuiSvgIcon-root {
  color: var(--autocomplete-icon-color) !important;
}

.MuiAutocomplete-listbox {
  border: none;
  outline: none;
  background-color: var(--autocomplete-bg-color) !important;
}

.MuiAutocomplete-listbox ::-webkit-scrollbar-track {
  color: var(--suggestions-bg-color) !important;
}

.MuiAutocomplete-inputRoot.MuiOutlinedInput-root.MuiInputBase-sizeSmall {
  padding: 8px;
  border-radius: 8px;
}

.MuiAutocomplete-option,
.MuiAutocomplete-option:active {
  font-weight: 300;
  color: var(--text-color) !important;
  background-color: var(--autocomplete-bg-color) !important;
  border: 0px 2px 2px 0px solid var(--background-color) !important;
  overflow: hidden;
}

.MuiAutocomplete-option:hover {
  color: var(--text-color) !important;
  background-color: var(--autocomplete-hover-bg-color) !important;
}

.MuiAutocomplete-noOptions {
  color: var(--text-secondary-color);
  background-color: var(--autocomplete-bg-color);
}

/**********/
/* Switch */

.MuiSwitch-track {
  background-color: var(--switch-track-color) !important;
}

/********/
/* Menu */

.MuiMenu-root .MuiPaper-root {
  color: var(--suggestions-menu-text-color);
  background-color: var(--suggestions-menu-bg-color);
}

/*********/
/* Radio */

.MuiRadio-root {
  svg {
    color: var(--color-smoke);
  }

  &.Mui-checked svg {
    color: var(--color-acqua);
  }
}

/***********/
/* Tooltip */

.MuiTooltip-popper {
  @supports (backdrop-filter: blur(1px)) {
    filter: opacity(0.8);
    backdrop-filter: blur(2px);
  }
}

.MuiTooltip-tooltip {
  @include primary-font;
  padding-left: 16px !important;
  padding-right: 16px !important;
  border-radius: 30px !important;
  color: var(--tooltip-text-color) !important;
  font-size: 12px;
  font-weight: 500 !important;
  background-color: var(--tooltip-bg-color) !important;
}

.MuiTooltip-arrow {
  color: var(--tooltip-bg-color) !important;
}

/* Dialog */

.MuiDialog-root .MuiDialog-paper {
  border-radius: 20px;
  background-color: var(--dialog-bg-color);
}

.MuiDialog-root .MuiDialogTitle-root {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  height: 60px;
  padding: 0 20px;
  font-size: 18px;
  line-height: 20px;
  font-weight: bold;
  color: var(--text-color);
}

.MuiDialog-root .MuiDialogContent-root {
  display: flex;
  flex-direction: column;
  gap: 12px;
  margin-bottom: 20px;
  padding: 0;
  overflow-y: visible;
}

.MuiDialog-root .MuiDialogActions-root {
  margin-top: 12px;
  padding: 16px;
  border-top: 1px solid var(--color-graphite);
}
