@import "./typography.scss";

body {
  @include primary-font;
  display: block;
  margin: 0 auto;
  padding: 0px;
  width: 100%;
  caret-color: #00b4cc;
  background-color: var(--background-color) !important;
  font-size: 16px;
  font-weight: 400;
  letter-spacing: 0.02em;
  overflow-x: hidden;
}

a,
a:hover {
  color: inherit;
}

input {
  outline: none;
}

*::-webkit-scrollbar {
  width: 0.4em;
}

*::-webkit-scrollbar-track {
  box-shadow: inset 0 0 0px var(--background-color);
}

*::-webkit-scrollbar-thumb {
  background-color: var(--pill-bg-color);
  outline: 0px solid transparent;
}

::-moz-selection {
  /* Code for Firefox */
  color: black;
  background: var(--primary-color);
}

::selection {
  color: black;
  background: var(--primary-color);
}

/* BUTTONS */

.btn-light {
  color: var(--suggestions-footer-color) !important;
  font-weight: 500 !important;
  background-color: transparent !important;
  border: 0px !important;
}

.btn-light {
  margin-right: 10px !important;
}

.btn-light[disabled] {
  color: rgb(245, 245, 245) !important;
}

.btn {
  box-shadow: none !important;
}

/* Forms */
textarea {
  resize: none !important;
  line-height: 30px !important;
}

textarea:focus,
textarea.form-control:focus,
input.form-control:focus,
input[type="text"]:focus,
input[type="password"]:focus,
input[type="email"]:focus,
input[type="number"]:focus,
[type="text"].form-control:focus,
[type="password"].form-control:focus,
[type="email"].form-control:focus,
[type="tel"].form-control:focus,
[contenteditable].form-control:focus {
  box-shadow: none;
}

.form-control:focus {
  color: #212529;
  background-color: #fff;
  border-color: #86b7fe;
  outline: 0;
  -webkit-box-shadow: none !important;
  box-shadow: 0 !important;
}

small {
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 16px;
  letter-spacing: 0.2em;
  text-transform: uppercase;
  color: var(--text-secondary-color);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.MuiSkeleton-circular {
  border-radius: 1000px !important;
}

.alert {
  background-color: var(--background-color) !important;
}

.alert-secondary {
  color: var(--text-secondary-color) !important;
  border-color: var(--border-color) !important;
}

span[class$="indicatorSeparator"] {
  display: none;
}

div[class$="-control"]:hover {
  border-color: var(--border-color) !important;
}

/* Placeholder */

::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: var(--input-placeholder-color) !important;
  opacity: 1; /* Firefox */
}

:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: var(--input-placeholder-color) !important;
}

::-ms-input-placeholder {
  /* Microsoft Edge */
  color: var(--input-placeholder-color) !important;
}
