/** Fade animation for CSSTransition */

.fade-enter {
  opacity: 0;
}

.fade-enter-active,
.fade-enter-done {
  opacity: 1;
  transition: opacity 150ms ease-in;
}
.fade-exit {
  opacity: 1;
}

.fade-exit-active,
.fade-exit-done {
  opacity: 0;
  transition: opacity 100ms ease-in;
}

/** Shrink width animation for CSSTransition */

// Fallback to the width set to the element
// .shrink-width-enter {}

.shrink-width-enter-active,
.shrink-width-enter-done {
  width: 0 !important;
  transition: width 300ms ease-in-out;
}
.shrink-width-exit {
  width: 0 !important;
}

.shrink-width-exit-active,
.shrink-width-exit-done {
  transition: width 300ms ease-in-out;
}
